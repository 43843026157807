import { v4 as uuidv4 } from 'uuid'
import { getDefaultClaimSteps } from '~/shared/claimSteps'
import fnolAdditionalQuestions from '~/shared/fnolAdditionalQuestions'

export default () => ({
  grpcSessionId: uuidv4(),
  contacts: [],
  claimNumber: '',
  policyInfo: {
    dateOfBirth: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    dateOfLoss: '',
    policyHolderFirstName: '',
    policyHolderLastName: '',
    doesKnowPolicyNumber: '',
    userInputPolicyNumber: '',
  },
  policyHolderContactInfo: {
    policyHolderFirstName: '',
    policyHolderLastName: '',
    policyHolderEmail: '',
    policyHolderPhoneNumber: '',
  },
  notifier: {
    firstName: '',
    lastName: '',
    relationToInsured: '',
    email: '',
    phone: '',
    acceptsTextMessages: false,
    acceptsEmailMessages: false,
    isPolicyHolder: null,
    acceptFraudDisclaimer: null,
    notifierType: '',
  },
  policyNumber: '',
  causeOfDamage: '',
  damageType: '',
  propertyDamage: {
    damages: [],
    anyUnfinishedDamagedRooms: null,
    hasExteriorDamage: null,
    numberRoomsDamaged: null,
    numberStructuresDamaged: null,
    numberItemsDamaged: null,
    descriptionOfDamagedProperty: '',
    lossOfLife: null,
  },
  doesKnowSourceOfDamage: null,
  sourceOfDamage: null,
  damagesDescription: '',
  vendorAssignments: [],
  taskAssignments: [],
  attachments: [],
  additional: fnolAdditionalQuestions(),
  claimSteps: getDefaultClaimSteps(),
  currentStep: 0,
  txKey: '',
  partnerContactOptIns: [{
    task: 'water-mitigation',
    canContact: null,
  }, {
    task: 'hosta',
    canContact: null,
  }],
})
