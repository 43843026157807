import { getDefaultClaimSteps } from '~/shared/claimSteps'
import fnolAdditionalQuestions from '~/shared/fnolAdditionalQuestions'

export default {
  clearPolicyInfo({ commit }) {
    commit('setPolicyInfo', {
      dateOfBirth: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalCode: '',
      country: '',
      dateOfLoss: '',
      policyHolderLastName: '',
      policyHolderFirstName: '',
      doesKnowPolicyNumber: '',
      userInputPolicyNumber: '',
    })
  },
  clearNotifier({ commit }) {
    commit('setNotifierInfo', {
      firstName: '',
      lastName: '',
      relationToInsured: '',
      email: '',
      phone: '',
      acceptsTextMessages: false,
      acceptsEmailMessages: false,
      acceptFraudDisclaimer: null,
      isPolicyHolder: null,
      notifierType: '',
    })
  },
  clearPropertyDamageQuestions({ commit }) {
    commit('setAnyUnfinishedDamagedRooms', null)
    commit('setHasExteriorDamage', null)
    commit('setNumberRoomsDamaged', null)
    commit('setNumberStructuresDamaged', null)
    commit('setNumberItemsDamaged', null)
    commit('setLossOfLife', null)
  },
  clearDamages({ commit, dispatch }) {
    commit('setPropertyDamages', [])
    dispatch('clearPropertyDamageQuestions')
  },
  clearPartnerContactOptIns({ commit, state }) {
    state.partnerContactOptIns.forEach((optIn) => {
      commit('setPartnerContactOptInsStatus', { task: optIn.task, canContact: null })
    })
  },
  clearApplicationState({ commit, dispatch }) {
    dispatch('clearDamages')
    dispatch('clearPolicyInfo')
    dispatch('clearNotifier')
    dispatch('clearPartnerContactOptIns')
    commit('setPolicyNumber', '')
    commit('setClaimNumber', '')
    commit('setAdditionalDetails', fnolAdditionalQuestions())
    commit('clearTxKey')
    commit('setClaimSteps', getDefaultClaimSteps())
    commit('clearPolicyHolderContactInfo')
    commit('setCauseOfDamage', '')
    commit('setDamageType', '')
    commit('setSourceOfDamage', null)
    commit('setDoesKnowSourceOfDamage', null)
    commit('setDamagesDescription', '')
    commit('setVendorAssignments', [])
    commit('resetAttachments')
    commit('resetCurrentStepToZero')
    commit('resetContacts')
  },
  clearInjuryAndPropertyOwnerInfo({ commit }) {
    commit('deleteContactsByLabel', 'Injured Person')
    commit('deleteContactsByLabel', 'Property Owner')
    commit('setOwnerHasAttorney', null)
    commit('setInjuredHasAttorney', null)
    commit('setHasInjuredPersonsInformation', null)
    commit('setHasPropertyOwnerInformation', null)
    commit('setDidReceiveMedicalTreatment', null)
    commit('setLossOfLife', null)
    commit('setDescriptionOfTreatment', '')
  },
}
